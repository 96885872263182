import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
  }

  copyLink(event) {
    console.log(event.target)

    var modal = document.getElementById(`shareLinkModal${event.target.dataset.id}`)
    console.log(modal)
    const copyText = modal.querySelector('#copyText')
    const helpText = modal.querySelector('#helpBlock')
    copyText.select();    // Selects the text inside the input
    document.execCommand('copy');    // Simply copies the selected text to clipboard
    helpText.innerHTML = "Link Copied!"
}

  toggleBusinessClosureDates(event) {
    console.log(event.target)
    var radio = event.target
    var offDatesSelection = document.getElementById('offDates')
    var payOffDatesSelection = document.getElementById('payOffDates')
    var selectDates = flatpickr(document.querySelector("input[name='selected_dates']"))
    if (radio.value == 'yes') {
      offDatesSelection.classList.remove('d-none')

      var start = flatpickr(document.querySelector("input[name='start_date']"))
      var endDate = flatpickr(document.querySelector("input[name='end_date']"))

      start.set('minDate', '2023-12-01')
      endDate.set('minDate', '2023-12-01')

      start.set('maxDate', '2024-01-15')
      endDate.set('maxDate', '2024-01-15')

      var workingDays = document.getElementById('contractorData').dataset.workDays.split(",")
      start.set('onChange', function(selectedDates, dateStr, instance) {

        console.log(endDate.selectDates)
        if (selectedDates.length > 0  && endDate.selectedDates.length > 0) {
          payOffDatesSelection.classList.remove('d-none')
          selectDates.set('minDate', start.selectedDates[0])
          selectDates.set('maxDate', endDate.selectedDates[0])
          selectDates.set('mode', 'multiple')
          selectDates.set('conjunction', " / ")
          selectDates.set('disable', [function(date) {
            return !(workingDays.includes(date.getDay().toString()))
          }])

          let days = []
          let s = document.querySelector("input[name='start_date']").value
          let e = document.querySelector("input[name='end_date']").value
          for(let date = new Date(s); date <= new Date(e); date.setDate(date.getDate() + 1)) {
            let day = date.getDay().toString()

            if (workingDays.includes(day)) {
              days.push(date)
            }
          }

          console.log(days);


          console.log(days);
          document.getElementById('pay_all_label').innerHTML = `All Working Days (Total of ${days.length})`
        }
      })

      endDate.set('onChange', function(selectedDates, dateStr, instance) {

        if (selectedDates.length > 0  && start.selectedDates.length > 0) {
          payOffDatesSelection.classList.remove('d-none')

          selectDates.set('minDate', start.selectedDates[0])
          selectDates.set('maxDate', endDate.selectedDates[0])
          selectDates.set('mode', 'multiple')
          selectDates.set('conjunction', " / ")
          selectDates.set('disable', [function(date) {
            return !(workingDays.includes(date.getDay().toString()))
          }])

          let days = []
          let s = document.querySelector("input[name='start_date']").value
          let e = document.querySelector("input[name='end_date']").value
          for(let date = new Date(s); date <= new Date(e); date.setDate(date.getDate() + 1)) {
            let day = date.getDay().toString()

            if (workingDays.includes(day)) {
              days.push(date)
            }
          }

          console.log(days);


          console.log(days);
          document.getElementById('pay_all_label').innerHTML = `All Working Days (Total of ${days.length})`
        }
      })

    } else {
      offDatesSelection.classList.add('d-none')
      payOffDatesSelection.classList.add('d-none')
      document.getElementById('selectedDatesToPay').classList.add('d-none')
    }
  }

  togglePayOffDates(selectedDates, dateStr, instance) {
    console.log(instance)
    console.log(dateStr)
  }

  toggleSelectDatesPicker(event) {
    if (event.target.checked && event.target.value == 'pay_selected') {
      document.getElementById('selectedDatesToPay').classList.remove('d-none')
    } else {
      document.getElementById('selectedDatesToPay').classList.add('d-none')
    }
  }

  toggleCashBonusPayOption(event) {
    console.log(event.target.value)
    let transfeeOption = document.getElementById('cashBonusPayOptionContainer')
    let payrollReleased = document.getElementById('payrollInfo').dataset.decemberPayrollReleased

    if (payrollReleased == 'false') {
      return;
    }

    if (!!event.target.value ) {
      transfeeOption.classList.remove('d-none')
    } else {
      transfeeOption.classList.add('d-none')
    }
  }
}
