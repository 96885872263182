import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "form", "buildButton", "client", "contractor", "currency", "gst", "invoiceNo", "type", "taxRow", "taxRowTemplate", "startDate", "endDate" ]

    connect() {
        if (this.hasFormTarget && this.typeTarget.value == 'TRI') {
            this.clientTarget.readOnly = true
            this.contractorTarget.readOnly = true
            this.currencyTarget.readOnly = true
        }
        this.taxRowToggle()

        if (this.hasFormTarget) {
            this.validateInputFields();
        }
    }

    validateInputFields() {
        var fields = document.getElementById('invoice-form').querySelectorAll('input, textarea');
        var formInputFields = Array.from(fields).filter(
            input => {
                if (input.name.includes('invoice_items_attributes')) {
                    return input.type !== 'hidden' && input.parentElement.parentElement.parentElement.style.display !== 'none'
                    // For Nested Attriutes, Since nested attributes when 'Removed' are not disabled or hidden, But what it does
                    // is add display:none to its parent TR
                } else {
                    return input.type !== 'hidden'
                }
            }
        );
        var filledInputs = Array.from(fields).filter(
            input => {
                if (input.name.includes('invoice_items_attributes')) {
                    return input.value !== '' && input.type !== 'hidden' && input.parentElement.parentElement.parentElement.style.display !== 'none'
                    // For Nested Attriutes, Since nested attributes when 'Removed' are not disabled or hidden, But what it does
                    // is add display:none to its parent TR
                } else {
                    return input.value !== '' && input.type !== 'hidden'
                }
            }
        );
        var submitBtn = document.getElementById('pushInvoiceToXero');

        console.log(formInputFields); // TODO: Remove After Regression
        console.log(filledInputs); // TODO: Remove After Regression

        if (formInputFields.length == filledInputs.length) {
            submitBtn.disabled = false;
            submitBtn.style.cursor = '';
        } else {
            submitBtn.disabled = true;
            submitBtn.style.cursor = 'not-allowed';
        }
    }

    autofillContractor(e) {
        this.clientTarget.readOnly = true
        this.contractorTarget.readOnly = true
        this.currencyTarget.readOnly = true

        const authenticityToken = document.querySelector('meta[name="csrf-token"]').content
        const id = this.clientTarget.options[this.clientTarget.selectedIndex].value

        const url = '/admin/clients/' + id + '/contractors/invoiceable.json'

        fetch(url, {
            credentials: 'include',
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-CSRF-Token': authenticityToken
            }
        })
            .then(response => response.json())
            .then(function(data) {
                if (data['contractors'].length > 0) {
                    var html = '<option></option>'

                    data.contractors.forEach(function(contractor, index) {
                        html += '<option value="' + contractor.id + '">' + contractor.name + '</option>'
                    })

                    document.querySelector('select[name="invoice[contractor_id]"]').innerHTML = html
                }

                document.querySelector('select[name="invoice[currency_id]"]').value = data.currency_id
                document.querySelector('input[name="invoice[gst]"]').value = data.gst

                document.querySelector('select[name="invoice[client_id]"]').readOnly = false
                document.querySelector('select[name="invoice[contractor_id]"]').readOnly = false
                document.querySelector('select[name="invoice[currency_id]"]').readOnly = false
            })
            .then(function() {
                const client_id = document.querySelector('select[name="invoice[client_id]"]').options[
                    document.querySelector('select[name="invoice[client_id]"]').selectedIndex
                ].value
                const url = '/admin/clients/' + client_id + '.json'

                fetch(url, {
                    credentials: 'include',
                    method: 'GET',
                    headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                      'X-CSRF-Token': authenticityToken
                    }
                })
                .then(response => response.json())
                .then(function(data) {
                    const url = '/admin/companies/' + data.company_id + '/new-invoice-no.json'
                    fetch(url, {
                        credentials: 'include',
                        method: 'GET',
                        headers: {
                          'Accept': 'application/json',
                          'Content-Type': 'application/json',
                          'X-CSRF-Token': authenticityToken
                        }
                    })
                    .then(response => response.json())
                    .then(function(data) {
                        if (data.error) {
                            window.alert(data.error)
                        } else {
                            document.querySelector('input[name="invoice[invoice_no]"]').value = data.invoice_no
                        }
                    })
                    .catch(error => window.alert(error))
                })
            })
            .catch(error => window.alert(error))
    }

    build(e) {
        e.preventDefault()

        if (this.startDateTarget.value > this.endDateTarget.value) {
            window.alert("Start date should be prior to end date")
            return
        }

        this.buildButtonTarget.readOnly = true
        this.formTarget.action = this.buildButtonTarget.href
        this.formTarget.method = 'post'
        this.formTarget.querySelector('input[name="authenticity_token"]').value = document.querySelector('meta[name="csrf-token"]').content

        const method = this.formTarget.querySelector('input[name="_method"]')
        if (method !== null) {
            method.value = ''
        }
        this.formTarget.dispatchEvent(new CustomEvent('submit', { bubbles: true, cancelable: true }))
    }

    buildAdjustment(e) {
        if (e.currentTarget.value == 'Adjustment' || e.currentTarget.value == 'Currency Adjustment') {
            this.formTarget.action = '/admin/invoices/build-adjustment?type=' + encodeURIComponent(e.value)
            this.formTarget.method = 'post'
            this.formTarget.querySelector('input[name="authenticity_token"]').value = document.querySelector('meta[name="csrf-token"]').content

            const method = this.formTarget.querySelector('input[name="_method"]')
            if (method !== null) {
                method.value = ''
            }

            this.formTarget.dispatchEvent(new CustomEvent('submit', { bubbles: true, cancelable: true }))
        } else if (e.currentTarget.value == 'Contract Buy-out') {
            let contractorDetails = document.getElementById('contractorDetails')

            if (contractorDetails == null) { return }

            let descElementName = e.currentTarget.name.replace('name', 'description')
            let descriptionTextArea = document.getElementsByName(descElementName)[0]

            let contractorName = contractorDetails.dataset['name']
            let contractorPosition = contractorDetails.dataset['position']
            let contractorWorkStatus = contractorDetails.dataset['workStatus']

            console.log(descriptionTextArea)

            descriptionTextArea.value = contractorWorkStatus + ' ' + contractorPosition + ' [' + contractorName + '] BUY-OUT'
        }
    }

    taxRowToggle() {
        const gst = (this.gstTarget.value == 'true')

        if (gst) {
            if (this.taxRowTarget.innerHTML == '') {
                const content = this.taxRowTemplateTarget.innerHTML
                this.taxRowTarget.innerHTML = content
            }
        } else {
          this.taxRowTarget.innerHTML = ''
        }
    }

    copyInvoiceLink() {
        const copyText = document.getElementById('copyText')
        const helpText = document.getElementById('helpBlock')
        copyText.select();    // Selects the text inside the input
        document.execCommand('copy');    // Simply copies the selected text to clipboard
        helpText.innerHTML = "Link Copied!"
    }
}
