import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["fileUpload", "fileUploadLabel", "filePreviewContainer"];

  connect() {
    this.uploadedFiles = new DataTransfer();
    this.fileUploadTarget.addEventListener('change', this.updateFileChosen.bind(this));
  }

  openFileUpload() {
    this.fileUploadTarget.click()
  }

  updateFileChosen(event) {
    this.filePreviewContainerTarget.innerHTML = '';
    var label = this.fileUploadLabelTarget

    var existingFileCount = Number(this.fileUploadTarget.dataset.existingFileCount) || 0

    if (this.fileUploadTarget.files.length == 0) {
      if (this.uploadedFiles.length > 0) {
        this.fileUploadTarget.files = this.uploadedFiles;
        this.populateImagePreview();
      } else {
        label.innerHTML = 'Upload'
        label.parentElement.classList.remove('mt-3')
      }
      return;
    }

    var errorMsg = this.fileUploadTarget.parentElement.parentElement.querySelector('#fileInputError')
    errorMsg.classList.add('d-none')
    var maxFileSize = false;

     // 1MB - 1048576 bytes
     Array.from(this.fileUploadTarget.files).forEach(file => {
      if (file.size > 10485760) {
        if (this.fileUploadTarget.files.length == 1) {
          this.fileUploadTarget.files = (new DataTransfer()).files
        }

        maxFileSize = true;
        this.retainPreviouslyUploadedImages()

        errorMsg.innerHTML = "Failed to Upload Files. Max File Size per Attachment is 10MB "
        errorMsg.classList.remove('d-none')

        return;
      }
    });

    if (!maxFileSize) {
      if (this.fileUploadTarget.files.length > 0 && this.fileUploadTarget.multiple ) {
        label.innerHTML = 'Add more Files'
        label.parentElement.classList.add('mt-3')
        var updatedFileList = new DataTransfer();

        var files = this.uploadedFiles
        for (var i = 0; i < files.length; i++) {
          var file = files[i]
          updatedFileList.items.add(file)
        }

        var { files } = this.fileUploadTarget
        for (var i = 0; i < files.length; i++) {
          var file = files[i]
          updatedFileList.items.add(file)
        }

        this.fileUploadTarget.files = updatedFileList.files
      } else {
        label.innerHTML = 'Upload'
        label.parentElement.classList.remove('mt-3')
      }

      if (this.fileUploadTarget.files.length > (8 - existingFileCount)) {
        errorMsg.innerHTML = "You can only select up to 8 attachments. Remove an uploaded file if you wish to replace"
        errorMsg.classList.remove('d-none')
        this.retainPreviouslyUploadedImages()
      }
    }

    this.uploadedFiles = this.fileUploadTarget.files
    this.populateImagePreview();
  }

  populateImagePreview() {
    let index = 0
    Array.from(this.fileUploadTarget.files).forEach(file => {
      let container = document.createElement('div')
      let borderedDiv = document.createElement('div')
      let fileName = document.createElement('div')
      let removeBtn = document.createElement('span')

      container.id = file.name
      removeBtn.innerHTML = `<i class="fas fa-times-circle" data-index='${index}'></i>`
      removeBtn.style.position = 'absolute'
      removeBtn.style.right = '-10px'
      removeBtn.style.top = '-12px'
      removeBtn.style.fontSize = '1.2rem'
      removeBtn.style.cursor = 'pointer'
      removeBtn.dataset.index = index
      removeBtn.addEventListener('click', this.removeFile.bind(this))

      fileName.innerHTML = `${file.name} - ${(file.size / 1048576).toFixed(2)}MB`
      fileName.style.fontSize = '0.8rem'
      fileName.style.width = '190px'
      fileName.style.wordWrap = 'break-word'
      fileName.style.marginTop = "12px";

      if (this.fileUploadTarget.multiple) {
        container.style.width = "210px"
      }

      container.style.minHeight = "175px"
      container.classList.add('me-3')
      container.classList.add('preview-container')
      container.classList.add('in-out')

      borderedDiv.appendChild(removeBtn)
      if (file.type == 'application/pdf') {
        let pdfIcon = document.createElement('div')
        pdfIcon.classList.add('d-flex')
        pdfIcon.classList.add('align-items-center')
        pdfIcon.classList.add('justify-content-center')
        container.style.minHeight = "230px"

        pdfIcon.innerHTML = '<i class="fas fa-file-pdf" style="font-size: 5.5rem; color: black;">'
        borderedDiv.appendChild(pdfIcon)
      } else {
        let previewImage = document.createElement('img')
        previewImage.style.width = '195px'
        previewImage.style.height = '160px'
        previewImage.style.alignSelf = "end"
        previewImage.src = URL.createObjectURL(file)
        borderedDiv.appendChild(previewImage)
      }
      borderedDiv.appendChild(fileName)

      borderedDiv.style.position = 'relative';
      borderedDiv.style.border = "1px solid #BFD1E5";
      borderedDiv.style.borderRadius = "4px";
      borderedDiv.style.padding = "8px";
      borderedDiv.style.textAlign = "center";
      borderedDiv.style.height = "100%";
      borderedDiv.style.display = "grid";

      container.appendChild(borderedDiv)
      // container.appendChild(fileName)

      this.filePreviewContainerTarget.append(container)
      index++
    })
  }

  retainPreviouslyUploadedImages() {
    var dataTransfer = new DataTransfer()
    var files = this.uploadedFiles

    for (var i = 0; i < files.length; i++) {
      var file = files[i]
      if (i < 5) {
        dataTransfer.items.add(file)
      }
      this.fileUploadTarget.files = dataTransfer.files
    }
  }

  removeActiveStorageFile(event) {
    const authenticityToken = document.querySelector('meta[name="csrf-token"]').content

    fetch('/project_activation/remove_project_attachment', {
      method: 'POST',
      headers: {
        'Accept': 'text/javascript',
        'Content-Type': 'application/json',
        'X-CSRF-Token': authenticityToken
      },
      body: JSON.stringify({ id: event.target.dataset.blobId }),
    })
    .then(response => response.json())
    .then(function(data) {
       let fileUploadTarget = document.querySelector(`#${event.target.dataset.fieldName}`)
       let existingFileCount = Number(fileUploadTarget.dataset.existingFileCount)
       if (existingFileCount > 0) {
         fileUploadTarget.dataset.existingFileCount = existingFileCount - 1;
       }

       event.target.closest('.file-upload-preview-container').parentElement.remove();
    })
  }

  removeFile(event) {
    var dataTransfer = new DataTransfer()
    var { files } = this.fileUploadTarget
    var index = event.target.dataset.index

    for (var i = 0; i < files.length; i++) {
      var file = files[i]
      if (Number(index) !== i) dataTransfer.items.add(file)
      this.fileUploadTarget.files = dataTransfer.files
      this.uploadedFiles = new DataTransfer()
    }

    this.fileUploadTarget.dispatchEvent(new Event('change'))
    event.target.closest('.preview-container').remove();
  }
}
