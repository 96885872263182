import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['numbers', 'total']

    connect() {
        this.calculate()
    }

    calculate(e) {
        var sum = 0

        this.numbersTarget.querySelectorAll('input.summable').forEach(function (el) {
            var val = parseFloat(el.value || 0)
            console.log(val)
            sum += val
        })

        sum = Number(sum).toFixed(2)
        this.totalTarget.value = sum
    }
}
