const images = require.context("../images", true)

import * as ActiveStorage from "@rails/activestorage"
import "channels"

import mrujs from "mrujs"
import "@hotwired/turbo-rails"

window.Turbo = Turbo
mrujs.start()

require("trix")
require("@rails/actiontext")

// require("channels")

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

window.Stimulus = Application.start()

const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
Stimulus.load(definitionsFromContext(context))

import { Autocomplete } from "stimulus-autocomplete"
Stimulus.register("autocomplete", Autocomplete)

import CheckboxSelectAll from "stimulus-checkbox-select-all"
Stimulus.register("checkbox-select-all", CheckboxSelectAll)

import { Tooltip, Toast, Popover } from "bootstrap"

import "chartkick/chart.js"

import "./page_navigation_loader"
import "./session_timeout"
import "./fade_alerts"

import "src/application.scss"

import * as bootstrap from 'bootstrap'

import intlTelInput from 'intl-tel-input';

// const input = document.querySelector("#phone");
// intlTelInput(input, {
//   separateDialCode: true,
//   utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.16/js/utils.js",
//   preferredCountries: ["us", "au", "nz", "ph"]
// });

document.addEventListener("turbo:load", function() {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
});


document.addEventListener("turbo:frame-load", function() {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
});