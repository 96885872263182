import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    reset() {
        document.querySelector('#companies-list').classList.add('d-none')
        // document.querySelector('#countries-list').classList.add('d-none')
        // document.querySelector('#selection-list').classList.add('d-none')
    }

    showCompanies(e) {
        this.reset()
        document.querySelector('#companies-list').classList.remove('d-none')
    }

    showCountries(e) {
        this.reset()
        document.querySelector('#countries-list').classList.remove('d-none')
    }

    showSelection(e) {
        this.reset()
        document.querySelector('#selection-list').classList.remove('d-none')
    }
}
