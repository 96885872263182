document.addEventListener('turbo:load', function() {
  const flashMessage = document.querySelector('.flash-alert');

  const fadeOutFlashMessage = () => {
    const timer_id = setInterval(() => {
      const opacity = flashMessage.style.opacity;

      if (opacity > 0) {
        flashMessage.style.opacity = opacity - 0.02;
      } else {
        // Hide when the opacity value reaches 0
        flashMessage.style.display = 'none';

        // Stop setInterval
        clearInterval(timer_id);
      };
    }, 50);
  }

  if (flashMessage !== null) {
    flashMessage.style.opacity = 1;

    setTimeout(fadeOutFlashMessage, 60000);
  };
})
