import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "form", "clearButton", "filterButton", "syncButton" ]

    clear(e) {
        e.preventDefault()

        if (window.confirm('Are you sure you want to clear time entries for the selected dates?')) {
            this.clearButtonTarget.classList.add('disabled')
            this.formTarget.action = this.clearButtonTarget.href
            this.formTarget.querySelector('input[name="authenticity_token"]').value = document.querySelector('meta[name="csrf-token"]').content
            this.formTarget.dispatchEvent(new CustomEvent('submit', { bubbles: true, cancelable: true, detail: {} }))
        }
    }

    updateTimeEntryAddedOn(event) {
        console.log(event);
        event.preventDefault();
        var div = document.getElementById('updateTimeEntryAddedOn')
        var inputs = div.querySelectorAll("input, select, checkbox, textarea")
        var check = confirm("Do you want to update the Manual Time Entry's Added on Date?");

        if(check == true) {
            div.classList.remove('d-none')
            inputs.forEach(function (item, index) {
                item.disabled = false
            })
            event.currentTarget.classList.add('d-none')
        }
    }


    filter(e) {
        e.preventDefault()

        this.filterButtonTarget.classList.add('disabled')
        this.formTarget.action = this.filterButtonTarget.href
        this.formTarget.querySelector('input[name="authenticity_token"]').value = document.querySelector('meta[name="csrf-token"]').content

        // Because @rails/ujs is missing on new Turbo frame, we need to take care of submitting the correct value for includeLateCheckbox
        const includeLateHidden = this.formTarget.querySelector('input[type="hidden"][name="include_late"]')
        const includeLateCheckbox = this.formTarget.querySelector('input[type="checkbox"][name="include_late"]')

        if (includeLateCheckbox) {
          includeLateHidden.value = includeLateCheckbox.checked ? '1' : '0'
          includeLateCheckbox.remove()
        }

        this.formTarget.dispatchEvent(new CustomEvent('submit', { bubbles: true, cancelable: true, detail: {} }))
    }

    sync(e) {
        e.preventDefault()

        this.syncButtonTarget.classList.add('disabled')
        this.formTarget.action = this.syncButtonTarget.href.replace('.turbo_stream', '');
        console.log(this.formTarget)
        this.formTarget.querySelector('input[name="authenticity_token"]').value = document.querySelector('meta[name="csrf-token"]').content
        this.formTarget.dispatchEvent(new CustomEvent('submit', { bubbles: true, cancelable: true, detail: {} }))
    }

    hoursToday(event) {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = yyyy + '-' + mm + '-' + dd;

        document.getElementsByName('start_date')[0].value = today;
        document.getElementsByName('end_date')[0].value = today;
        if (window.location.href.includes('/contractor/timesheet')) {
            this.filterButtonTarget.click();
        } else {
            this.formTarget.dispatchEvent(new CustomEvent('submit', { bubbles: true, cancelable: true, detail: {} }))
        }
    }

    cutOffHours(event) {

        var startDate = new Date(event.target.dataset.startDate)
        var endDate = new Date(event.target.dataset.endDate)
        var startdd = String(startDate.getDate()).padStart(2, '0');
        var startmm = String(startDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var startyyyy = startDate.getFullYear();

        startDate = startyyyy + '-' + startmm + '-' + startdd;

        var endDD = String(endDate.getDate()).padStart(2, '0');
        var endMM = String(endDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var endYYYY = endDate.getFullYear();

        endDate = endYYYY + '-' + endMM + '-' + endDD;

        console.log(startDate);
        console.log(endDate);

        document.getElementsByName('start_date')[0].value = startDate;
        document.getElementsByName('end_date')[0].value = endDate;

        if (window.location.href.includes('/contractor/timesheet')) {
            this.filterButtonTarget.click();
        } else {
            this.formTarget.dispatchEvent(new CustomEvent('submit', { bubbles: true, cancelable: true, detail: {} }))
        }
    }
}
