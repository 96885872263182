import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "numbers", "tax", "total", "totalQuantity", "subtotal" ]

    connect() {
        this.calculate()
    }

    calculate(e) {
        var subtotal = 0
        var quantity = 0

        this.numbersTarget.querySelectorAll('.accounting-row').forEach(function (el) {
            const rowQuantity = parseFloat(el.querySelector('.accounting-quantity').value || 0)
            const rowRate = parseFloat(el.querySelector('.accounting-currency').value || 0)
            var lineTotal = 0

            if (el.querySelector('.row-total') !== null) {
                el.querySelector('.row-total').innerHTML = Number(rowQuantity * rowRate).toFixed(2)
            }

            // Dont Compute Removed Nested Form Item (detected if display is none)
            if (el.style.display !== 'none') {
                subtotal += (rowQuantity * rowRate)
                quantity += rowQuantity
            }
        })

        if (this.hasSubtotalTarget) {
            this.subtotalTarget.innerHTML = subtotal.toFixed(2)
            if (this.hasTaxTarget) {
                const tax = subtotal * 0.10
                this.taxTarget.innerHTML = tax.toFixed(2)
                this.totalTarget.innerHTML = Number(subtotal + tax).toFixed(2)
            } else {
                this.totalTarget.innerHTML = Number(subtotal).toFixed(2)
            }
        } else if (this.hasTotalTarget) {
            this.totalTarget.innerHTML = Number(subtotal).toFixed(2)
        }

        if (this.hasTotalQuantityTarget) {
            this.totalQuantityTarget.innerHTML = Number(quantity).toFixed(2)
        }

        this.totalTarget.innerHTML = Number(this.totalTarget.innerHTML).toLocaleString('en-US')
    }
}
