import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "excludeBox", "excludeCount", "form", "includeBox", "includeCount" ]

    include(e) {
        e.preventDefault()

        const selected = this.excludeBoxTarget.selectedOptions
        if (selected.length !== null) {
            for (let option of selected) {
                this.includeBoxTarget.appendChild(option.cloneNode(true))
            }

            var i = selected.length
            while (i--) {
                var current = this.excludeBoxTarget.selectedOptions[i]
                // current.parentNode.removeChild(current)
                current.remove()
            }
        }
        this.updateCounts()
    }

    exclude(e) {
        e.preventDefault()

        const selected = this.includeBoxTarget.selectedOptions
        if (selected.length !== null) {
            for (let option of selected) {
                this.excludeBoxTarget.appendChild(option.cloneNode(true))
            }

            var i = selected.length
            while (i--) {
                var current = this.includeBoxTarget.selectedOptions[i]
                // current.parentNode.removeChild(current)
                current.remove()
            }
        }
        this.updateCounts()
    }

    updateCounts() {
        this.excludeCountTarget.innerHTML = this.excludeBoxTarget.querySelectorAll('option').length
        this.includeCountTarget.innerHTML = this.includeBoxTarget.querySelectorAll('option').length
    }

    submit() {
        const options = this.includeBoxTarget.options
        if (options.length !== null) {
            for (let option of options) {
                option.selected = true
            }
        }
    }
}
