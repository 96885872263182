import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = { flashedUserActionIds: Array}

    connect() {
        checkUserActions();
        setInterval(checkUserActions, 10000);
    }

    flashForUserAction(userActionId, controllerNameAndAction) {
        switch (controllerNameAndAction) {
            case 'pay_slips#batch_generate':
                flash(userActionId, 'Payslips generated')
                break;
            case 'recruiters#generate_placements':
                flash(userActionId, 'Eligible placements report generated')
                break;
            case 'reports#generate_timesheet_remarks':
                flash(userActionId, 'Missing timesheet remarks report generated')
                break;
            case 'reports#generate_timesheet_report':
                flash(userActionId, 'Timesheet report generated')
                break;
        }
    }

    flash(userActionId, message) {
        for(var i=0; i< flashedUserActionIds.length; i++ ) {
            if(flashedUserActionIds[i] === userActionId) {
                return false
            }
        }

        let html = '';
        html += '<div class="alert alert-success alert-dismissable">';
        html += '<button type="button" class="btn" data-bs-dismiss="alert" aria-hidden="true">&times;</button>';
        html += '<div id="flash-content">';
        html += '<a href="/admin/user-actions/' + userActionId + '" class="text-success">' + message + ', click here to view</a>';
        html += '</div>';

        var div = document.createElement('div')
        div.innerHTML = html

        div.insertBefore(document.getElementById('content'))

        flashedUserActionIds.push(userActionId);
    }

    checkUserActions() {
        fetch('/admin/user-actions.json')
        .then(response => response.json())
        .then(function(data) {
            for(var i=0; i< data.length; i++ ) {

                if (data['completed'] && !data['acknowledged']) {
                    flashForUserAction(data['id'], data['controller_name'] + '#' + data['controller_action']);
                }
           }
        })
        .catch(error => {
            alert(error)
        })
    }
}
