import CheckboxSelectAll from "stimulus-checkbox-select-all"

export default class extends CheckboxSelectAll {
    connect() {
        this.checkboxAllTarget.addEventListener('change', this.toggleMenu)

        var self = this
        const updateButtons = document.querySelectorAll('.process-batch-archive')
        updateButtons.forEach(function(updateButton) {
            updateButton.addEventListener('click', self.batchArchive)
        })

        super.connect()
    }

    refresh() {
        super.refresh()
        this.toggleMenu()
    }

    toggle(e) {
        super.toggle(e)
        this.toggleMenu()
    }

    toggleMenu() {
        document.querySelector('#clientIds').dataset.ids = this.clientIds()

        const menu = document.querySelector('#clientSelectionMenu')
        if (this.checked.length > 0) {
            menu.classList.remove('d-none')
        } else {
            menu.classList.add('d-none')
        }
    }

    clientIds() {
        var clientIds = []
        this.checked.forEach(function(checkbox) {
            clientIds.push(parseInt(checkbox.value))
        })

        return clientIds
    }

    batchArchive(e) {
        e.preventDefault()

        var check = confirm("Archive Selected Clients ?");

        if (check == true) {
            document.querySelector('#spinner').style.display = '';

            var clientIds = document.querySelector('#clientIds').dataset.ids
            if (typeof clientIds == 'string') {
                clientIds = [ clientIds ]
            }

            const authenticityToken = document.querySelector('meta[name="csrf-token"]').content
            const url = '/admin/clients/batch_archive' + '?client_ids=' + clientIds.join(',')

            fetch(url, {
                credentials: 'include',
                method: 'GET',
                headers: {
                  'X-CSRF-Token': authenticityToken
                }
            }).then (
                setTimeout(function() {
                    document.querySelector('#spinner').style.display = 'none'

                    Turbo.visit('/admin/clients/archiveable_clients')
                  }, 2000)
            )
        }
        else {
            return;
        }
    }
}