import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        import("moment-timezone").then(moment => {
            window.moment = moment.default
            this.parsePage()
        })
    }

    disconnect() {
    }

    parsePage() {
        const unixTimestamps = document.querySelectorAll('.unix-timestamp')

        for (const el of unixTimestamps) {
            el.textContent = window.moment.unix(el.textContent).tz(moment.tz.guess()).format('MM/DD/YYYY h:mm:ss A z')
            el.classList.remove('unix-timestamp')
        }

        const longUnixTimestamps = document.querySelectorAll('.long-unix-timestamp')

        for (const el of longUnixTimestamps) {
            el.innerHTML = window.moment.unix(el.textContent).tz(moment.tz.guess()).format('MMMM Do YYYY h:mm A ') + '<span class="text-muted ml-1">' + moment.tz.guess().replace('_', ' ').split('/')[1] + '</span>'
            el.classList.remove('long-unix-timestamp')
        }

        const shortUnixTimestamps = document.querySelectorAll('.short-unix-timestamp')

        for (const el of shortUnixTimestamps) {
            el.innerHTML = window.moment.unix(el.textContent).tz(moment.tz.guess()).format('YYYY-MM-DD h:mm A ') + '<span class="text-muted ml-1">' + moment.tz.guess().replace('_', ' ').split('/')[1] + '</span>'
            el.classList.remove('short-unix-timestamp')
        }
    }
}
