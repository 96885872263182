import CheckboxSelectAll from "stimulus-checkbox-select-all"

export default class extends CheckboxSelectAll {
    connect() {
        this.checkboxAllTarget.addEventListener('change', this.toggleMenu)

        var self = this
        const updateButtons = document.querySelectorAll('.batch-update')
        updateButtons.forEach(function(updateButton) {
            updateButton.addEventListener('click', self.updateBatch)
        })

        super.connect()
    }

    refresh() {
        super.refresh()
        this.toggleMenu()
    }

    toggle(e) {
        super.toggle(e)
        this.toggleMenu()
    }

    toggleMenu() {
        document.querySelector('#payslipIds').dataset.ids = this.payslipIds()

        const menu = document.querySelector('#payslipsSelectionMenu')
        if (this.checked.length > 0) {
            menu.classList.remove('d-none')
        } else {
            menu.classList.add('d-none')
        }
    }

    payslipIds() {
        var payslipIds = []
        this.checked.forEach(function(checkbox) {
            payslipIds.push(parseInt(checkbox.value))
        })

        return payslipIds
    }
}
