import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    onSelInvoiceChange(event) {
      let selInvoice = event.target
      let invNo = selInvoice.value
      let form = selInvoice.closest('form')
      let tr = selInvoice.closest('tr')
      let refund = document.getElementById('refund');
      let info = this.getInvoiceInfo(invNo);

      tr.querySelectorAll('textarea')[0].value = info.details
      tr.querySelectorAll('input[type=number]')[0].value = info.hours
      let url = "/admin/contractors/end-of-contract/" + refund.dataset.id + "/rebuild.json";
      const authenticityToken = document.querySelector('meta[name="csrf-token"]').content

      let inputElements = form.elements
      let jsonObject = { refund_items_attributes: {} };

      // Serialize Form Data
      for (var i = 0; i < inputElements.length; i++) {
          var inputElement = inputElements[i];

          if (inputElement.name.includes('refund') ) {
            var name = inputElement.name.replace('refund', '').replace('[', '').replace(']', '')
            // input name example is refund[raw_hours]. We will remove the refund and the []. final result will eq to raw_hours

            if (name.includes('refund_items_attributes')) {
                // Reconstruct Nested Form Values
                let id = Number(name.match(/(\d+)/)[0])
                // get the Array ID from nested value e.g refund_items_attributes[0][raw_hours]

                if (jsonObject['refund_items_attributes'][id] === undefined) {
                    jsonObject['refund_items_attributes'][id] = {};
                }

                let key = name.replace("refund_items_attributes[" + String(id) + "]", '').replace('[', '').replace(']', '')
                jsonObject['refund_items_attributes'][id][key] = inputElement.value
            } else {
                jsonObject[name] = inputElement.value;
            }
          }
       }

       let finalJsonObject = { refund: jsonObject }

      fetch(url, {
        method: 'PUT',
        body: JSON.stringify(finalJsonObject),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': authenticityToken
        },
      }).then(response => response.json())
      .then(function(data) {
          console.log(data)
          form.outerHTML = data.html
          // Not sure whats the intended behavior here. But commented out it first because
          // It renders the initial HTML page and disregard the changes in the details textarea
          // and refundInvoiceHours
      })
      .catch(error => {
            alert(error);
        })
    }

    getInvoiceInfo(invNo) {
        let info = {};
        let invoiceDetails = document.getElementById('invoice-details').dataset.invoiceDetails
        let invoiceArray = JSON.parse(invoiceDetails)

        for(var i=0; i< invoiceArray.length; i++ ) {
            if( invoiceArray[i].invoice_no == invNo) {
                info = invoiceArray[i]
            }
        }

        return info;
    }

    copyApprovalLink() {
        const copyText = document.getElementById('copyText')
        const helpText = document.getElementById('helpBlock')
        copyText.select();    // Selects the text inside the input
        document.execCommand('copy');    // Simply copies the selected text to clipboard
        helpText.innerHTML = "Link Copied!"
    }
}
