import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "modal" ]

    connect() {
        if (modal.children.length > 0) {
            this.modalTarget.classList.add('modal-open')
            this.modalTarget.classList.add('show')
            this.modalTarget.style.display = 'block'
            this.modalTarget.removeAttribute('aria-hidden')
            this.modalTarget.setAttribute('aria-modal', true)
            this.modalTarget.setAttribute('role', 'dialog')
        }
    }

    close() {
        this.modalTarget.remove()
    }

    launchTermModal(event) {
        console.log(event)
        event.preventDefault();
        let termModal = document.getElementById('termModal')
        termModal.style.display = 'block'
        termModal.style.backgroundColor = 'rgb(0 0 0 / 75%)'

        let obj = JSON.parse(event.target.dataset.obj);

        document.getElementById('termID').value = obj.term_id
        document.getElementById('termStartDate').value = obj.hint_start_date
        document.getElementById('termEndDate').value = obj.hint_end_date
        document.getElementById('txtName').innerHTML = obj.contractor_name
        document.getElementById('txtOrg').innerHTML = obj.organization
    }

    closeTermModal(event) {
        let termModal = document.getElementById('termModal')
        termModal.style.display = ''
    }
}
