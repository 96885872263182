import Flatpickr from 'stimulus-flatpickr'

export default class extends Flatpickr {
    initialize() {
        this.config = {
            locale: {
                firstDayOfWeek: 1
            }
        }
    }
}