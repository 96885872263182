import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "checkbox", "form" ]
    static values = { checked: Boolean }

    toggle(event) {
        const authenticityToken = document.querySelector('meta[name="csrf-token"]').content
        const url = this.formTarget.action

        const data = {
            authenticity_token: authenticityToken,
            refund: { is_hidden: this.checkedValue }
        };

        fetch(url, {
            credentials: 'include',
            method: 'PUT',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-CSRF-Token': authenticityToken
            },
            body: JSON.stringify(data),
        })
    }

    get checked() {
        return this.checkboxTarget.checked
    }
}
