import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    handle(e) {
        e.preventDefault()

        if (!window.confirm('Are you sure you want to delete?')) {
            return
        }

        const authenticityToken = document.querySelector('meta[name="csrf-token"]').content
        const url = e.currentTarget.href + '.json'

        fetch(url, {
            credentials: 'include',
            method: 'DELETE',
            headers: {
              'X-CSRF-Token': authenticityToken
            }
        })
        .then(response => response.json())
        .then(function(data) {
            if (data.location) {
                Turbo.visit(data.location)
            } else {
                Turbo.visit(window.location)
            }
        })
    }
}
