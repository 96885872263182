import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	connect() {
		document.getElementById('searchSetting').addEventListener('keydown', function(e){
			var string = e.target.value

			var found = 0

			Array.from(document.getElementsByClassName('card-title')).forEach(titleElement => {
			  var formatted = titleElement.innerText.toLowerCase();
			  var card = titleElement.parentElement.parentElement.parentElement;
			  card.style.transition =  "opacity 1s ease-out";

			  if (formatted.includes(string.toLowerCase())) {
          card.style.display = 'flex';
          found++;
			  } else {
				  card.style.display = 'none';
			  }
			})

			if (found == 0) {
			  document.getElementById('noResultsFound').style.display = "block";
			} else {
			  document.getElementById('noResultsFound').style.display = "none";
			}
    })
	}
}
