import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "container", "button" ]

  connect() {
    this.buttonTarget.addEventListener('click', this.showOrHideContainer.bind(this))
  }

  showOrHideContainer() {
    if (this.containerTarget.checkVisibility()) {
      this.containerTarget.classList.add('d-none')
      this.buttonTarget.innerHTML = this.containerTarget.dataset.showLabel
    } else {
      this.containerTarget.classList.remove('d-none')
      this.buttonTarget.innerHTML = this.containerTarget.dataset.hideLabel
    }
  }
}
