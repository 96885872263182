import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "form" ]

    uncheckOthers(event) {
        if (event.target.checked != true) {
            return
        }

        this.formTarget.querySelectorAll('input[type="checkbox"]').forEach(function(checkbox) {
            if (checkbox.id != event.target.id) {
                checkbox.checked = false
            }
        })

    }
}
